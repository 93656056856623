import React from "react";

// import { Container } from './styles';

const Button: React.FC<any> = ({loading, text}) => {
  return (
    <button
      type="submit"
      className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      {loading ? "Carregando..." : text}
    </button>
  );
};

export default Button;
