import { useField } from "@unform/core";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

interface Props {
  name: string;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

export default function Media({ name, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  const [attachments, setAttachments] = useState<any>([]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files",
      clearValue(ref: HTMLInputElement) {
        setAttachments([]);
        ref.value = "";
      },
      setValue: (_, value) => {
        setAttachments(value);
      },
    });
  }, [fieldName, registerField]);

  const handlePreview = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files) return;

    if (attachments.length >= 10) {
      alert("Máximo de 10 fotos por publicação/story");
      return;
    }

    const newAttachments = [
      ...attachments,
      ...Array.from(files).map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ];
    setAttachments(newAttachments);
    updateInputFiles(newAttachments);
  };

  const updateInputFiles = (files: any) => {
    const dataTransfer = new DataTransfer();
    files.forEach((file: any) => {
      dataTransfer.items.add(file);
    });
    if (inputRef.current) {
      inputRef.current.files = dataTransfer.files;
    }
  };

  useEffect(() => {
    return () =>
      attachments.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [attachments]);

  const removeFile = (fileIndex: any) => {
    const newAttachments = attachments.filter(
      (_: any, index: any) => index !== fileIndex
    );
    setAttachments(newAttachments);
    updateInputFiles(newAttachments);
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <div className="mt-4" onClick={() => clearError()}>
      <label className="block text-sm font-medium text-gray-700">Media</label>
      <div
        onClick={handleClick}
        className="cursor-pointer relative block w-full px-3 py-6 mt-1 border-2 border-dashed border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <input
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
          accept="image/*,video/*"
          className="sr-only"
          multiple
        />
        <p className="text-center text-gray-400">
          Arraste e solte alguns arquivos aqui ou clique para selecionar os
          arquivos
        </p>
      </div>
      {error && <span className="text-red-500">{error}</span>}
      <div className="flex flex-row flex-wrap mt-4">
        {attachments?.map((file: any, index: number) => (
          <div key={index} className="relative w-24 h-24 m-1">
            {file.type.startsWith("image/") ? (
              <img
                src={file.preview}
                alt={file.name}
                className="absolute top-0 left-0 object-cover w-full h-full rounded-md"
              />
            ) : (
              <video
                src={file.preview}
                className="absolute top-0 left-0 object-cover w-full h-full rounded-md"
                controls
              />
            )}
            <button
              onClick={() => removeFile(index)}
              className="absolute top-1 right-1 px-2 py-1 text-[10px] bg-red-600 rounded-full text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="size-5"
              >
                <path
                  fillRule="evenodd"
                  d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
