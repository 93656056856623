import { useField } from "@unform/core";
import { useEffect, useRef, useState } from "react";

const Switch: any = ({ name, rest }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOn, setIsOn] = useState<number>(0);

  const toggleSwitch = () => {
    setIsOn(isOn === 0 ? 1 : 0);
  };

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    setIsOn(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="flex items-center">
      <input
        type="text"
        ref={inputRef}
        {...rest}
        value={isOn}
        defaultValue={defaultValue}
        className="sr-only"
      />
      <div
        className={`relative w-12 h-6 transition duration-200 ease-linear rounded-full ${
          isOn ? "bg-green-400" : "bg-gray-300"
        }`}
        onClick={toggleSwitch}
      >
        <span
          className={`absolute left-0 top-0 w-6 h-6 bg-white border-2 rounded-full transition transform duration-100 ease-linear cursor-pointer ${
            isOn
              ? "translate-x-full border-green-400"
              : "translate-x-0 border-gray-300"
          }`}
        ></span>
      </div>
      <span className="ml-3 text-gray-700">Fixar Publicação</span>
    </div>
  );
};

export default Switch;
