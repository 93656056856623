import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import Textarea from "../../ui/TextArea";
import Button from "../../ui/Button";
import Media from "../../Media";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { schemaErrorsPost } from "../../../validations/post-validation";
import { postStory } from "../../../services/basic-rest/stories/post-story";

interface FormAddPostProps {
  caption: string;
  type: string;
}

interface ParamTypes {
  id: string;
  highlightId: string;
}

const FormNewStory: React.FC = () => {
  const formRefAddStory = useRef<FormHandles>(null);
  const { highlightId, id } = useParams<ParamTypes>();
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit: SubmitHandler<FormAddPostProps> = async (data: any) => {
    setLoading(true);

    try {
      formRefAddStory?.current?.setErrors({});

      await schemaErrorsPost.validate(data, {
        abortEarly: false,
      });

      if (data?.file.length === 0) {
        formRefAddStory?.current?.setFieldError(
          "file",
          "Insira pelo menos 1 foto ou vídeo"
        );
        setLoading(false);
        return;
      }

      await postStory({
        ...data,
        file: data?.file[0],
        highlight_id: String(highlightId),
      })
        .then((resp) => {
          history.push(`/perfis/${id}/destaques/${highlightId}`);
        })
        .catch((error) => {
          console.log("error", error);

          // setMessage(error?.response?.data?.message);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefAddStory?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <>
      <Form
        ref={formRefAddStory}
        onSubmit={handleSubmit}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="mt-8 space-y-6"
      >
        <Textarea name="caption" label="Legenda" />
        <Media name="file" />
        <Button text="Criar Story" loading={loading} />
      </Form>
      <Link
        to={`/perfis/`}
        className="mt-2 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md group hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Voltar
      </Link>
    </>
  );
};

export default FormNewStory;
