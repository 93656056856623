import { apiAuth } from "../../http";

export const postProfile = async (body: any): Promise<any> => {
  const response = apiAuth()
    .post("/profiles", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data);
  return response;
};

// export const postProfile = async (body: any): Promise<any> => {
//   const formData = new FormData();

//   // Adicione os dados do perfil ao FormData
//   Object.keys(body).forEach((key) => {
//     formData.append(key, body[key]);
//   });

//   // Adicione a imagem ao FormData
//   if (body.photo) {
//     formData.append("photo", body.photo);
//   }

//   console.log("formData", formData);

//   const response = apiAuth()
//     .post("/profiles", formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//     .then(({ data }) => data);

//   return response;
// };
