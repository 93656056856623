import FormEditStory from "../../components/Forms/FormEditStory";
import Main from "../../components/Main";

const EditStory = () => {
  return (
    <Main>
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Editar Story</h2>
        <FormEditStory />
      </div>
    </Main>
  );
};

export default EditStory;
