import FormAddHighLight from "../../components/Forms/FormAddHighLight";
import Main from "../../components/Main";

const AddHighLight = () => {
  return (
    <Main>
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Adicionar Destaque</h2>
        <FormAddHighLight />
      </div>
    </Main>
  );
};

export default AddHighLight;
