import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react";

import { useField } from "@unform/core";

interface Props {
  name: string;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

export default function ImageInput({ name, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField } = useField(name);

  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (rest.defaultValue) {
      setPreview(rest.defaultValue as string);
    }
  }, [rest.defaultValue]);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      setPreview(null);
    }

    const previewURL: any = URL.createObjectURL(file as any);

    setPreview(previewURL);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files[0]",
      clearValue(ref: HTMLInputElement) {
        ref.value = "";
      },
      setValue(_: HTMLInputElement, value: string) {},
    });
  }, [fieldName, registerField, name]);

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <div className="flex justify-center items-center mt-4">
        <input
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
          accept="image/*"
          className="sr-only"
        />
        {preview ? (
          <div className="relative">
            <img
              src={preview}
              alt="Preview"
              className="w-32 h-32 rounded-full object-cover cursor-pointer"
              onClick={handleClick}
            />
          </div>
        ) : (
          <div
            onClick={handleClick}
            className="cursor-pointer w-32 h-32 bg-gray-200 rounded-full flex justify-center items-center"
          >
            <svg
              className="w-12 h-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
}
