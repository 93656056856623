import * as yup from "yup";

export const schemaErrorsLogin = yup.object().shape({
  password: yup
    .string()
    .min(8, "Senha de mínimo de 8 caracteres.")
    .required("Senha não pode ficar em branco."),
  email: yup
    .string()
    .email("Digite um email válido.")
    .required("E-mail não pode ficar em branco."),
});
