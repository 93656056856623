import { useEffect, useState, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import useLocalStorage from "./utils/use-local-storage";
import { AppContext } from "./providers/appProvider";
import LoadingPage from "./pages/Loading";
import { getAuth } from "./services/basic-rest/auth/get-auth";

export const RoutePublic = ({ component: Component, ...rest }: any) => {
  const [isLogged, setIsLogged] = useState<boolean | null>(null);
  const { setUser } = useContext(AppContext);
  const { accessToken } = useLocalStorage();

  useEffect(() => {
    accessToken !== null
      ? getAuth()
          .then((resp) => {
            const { me } = resp;
            setUser(me);
            setIsLogged(true);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setIsLogged(false);
            }
          })
      : setIsLogged(false);
  }, [isLogged, setUser, accessToken]);

  if (isLogged === null) {
    return <LoadingPage />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/perfis", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
