import React from "react";
import FormLogin from "../../components/Forms/FormLogin/index.";

const Login: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Acesse o painel</h2>
        <FormLogin />
      </div>
    </div>
  );
};

export default Login;
