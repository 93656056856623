import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import FormEditProfile from "../../components/Forms/FormEditProfile";
import { getProfileById } from "../../services/basic-rest/profiles/get-profile-by-id";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import Main from "../../components/Main";
import { deletePost } from "../../services/basic-rest/posts/delete-post";
import { deleteHighlight } from "../../services/basic-rest/highlight/delete-highlight";
import Thumbnail from "../../components/Thumbnail";

interface ParamTypes {
  id: string;
}

const EditProfile: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [posts, setPosts] = useState<any>([]);
  const [highlights, setHighLights] = useState<any>([]);
  const [profile, setProfile] = useState<any>();

  const { data } = useQuery(`profile_${id}`, () => getProfileById(id));

  useEffect(() => {
    setPosts(data?.posts);
    setHighLights(data?.highlights);
    setProfile(data);
  }, [data]);

  const onDeletePost = async (id: string) => {
    await deletePost(id)
      .then((resp) => {
        const newArray = [...posts];
        const row = newArray.filter((post: any) => post.id !== id);
        setPosts(row);
      })
      .catch((error) => {});
  };

  const onDeleteHighlight = async (id: string) => {
    await deleteHighlight(id)
      .then((resp) => {
        const newArray = [...highlights];
        const row = newArray.filter((highlight: any) => highlight.id !== id);
        setHighLights(row);
      })
      .catch((error) => {});
  };

  return (
    <Main>
      <div className="w-full max-w-lg p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Editar Perfil</h2>
        <FormEditProfile profile={profile} />
        <Link
          to="/perfis"
          className="mt-2 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md group hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Voltar
        </Link>
      </div>

      <div className="w-full max-w-lg p-8 bg-white rounded shadow-md mt-10">
        <h2 className="text-2xl font-bold text-center mb-2">Publicações</h2>
        <Link
          to={`/perfis/${data?.id}/posts/novo`}
          className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Adicionar Publicação
        </Link>
        <table className="w-full mt-8 border-collapse">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Imagem</th>
              <th className="px-4 py-2 border">Legenda</th>
              <th className="px-4 py-2 border">Ações</th>
            </tr>
          </thead>
          <tbody>
            {posts?.map((post: any, index: any) => (
              <tr key={index}>
                <td className="border">
                  <div className="relative">
                    {post.attachments.length >= 1 && (
                      <Thumbnail attachment={post.attachments[0]} />
                    )}
                  </div>
                </td>
                <td style={{ maxWidth: "200px" }} className="px-4 py-2 border">
                  {post.caption}
                </td>
                <td className="px-4 py-2 border">
                  <Link to={`/perfis/${data?.id}/posts/${post?.id}`}>
                    <button className="flex p-2 text-sm text-black text-center items-center justify-center bg-gray-200 rounded mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    </button>
                  </Link>
                  <button
                    onClick={() => onDeletePost(post?.id)}
                    className=" flex p-2 text-sm text-white text-center items-center justify-center bg-red-500 rounded  hover:bg-red-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="w-full max-w-lg p-8 bg-white rounded shadow-md mt-10 mb-10">
        <h2 className="text-2xl font-bold text-center">Destaques</h2>
        <Link
          to={`/perfis/${data?.id}/destaques/novo`}
          className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Adicionar Destaque
        </Link>
        <table className="w-full mt-8 border-collapse">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Imagem</th>
              <th className="px-4 py-2 border">Título</th>
              <th className="px-4 py-2 border">Ações</th>
            </tr>
          </thead>
          <tbody>
            {highlights?.map((highlight: any, index: any) => (
              <tr key={index}>
                <td className="border">
                  <div className="relative">
                    <Thumbnail attachment={highlight.stories[0]?.attachment} />
                  </div>
                </td>
                <td style={{ maxWidth: "200px" }} className="px-4 py-2 border">
                  {highlight.title}
                </td>
                <td className="px-4 py-2 border">
                  <Link to={`/perfis/${data?.id}/destaques/${highlight?.id}`}>
                    <button className=" flex p-2 text-sm text-black text-center items-center justify-center bg-gray-200 rounded mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    </button>
                  </Link>
                  <button
                    onClick={() => onDeleteHighlight(highlight?.id)}
                    className=" flex p-2 text-sm text-white text-center items-center justify-center bg-red-500 rounded  hover:bg-red-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Main>
  );
};

export default EditProfile;
