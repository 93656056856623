import React from "react";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { isVideoAttachment } from "../../helpers/is-video-attachment";

const PostModal = ({ isOpen, onClose, post, profile }: any) => {
  if (!isOpen) return null;

  const metadata = {
    imageUrl: "/path/to/image.jpg",
    author: "Author Name",
    authorAvatar: "/path/to/avatar.jpg",
    caption: "This is a caption of the post.",
    date: "May 24, 2024",
    readTime: "2 min read",
    comments: [
      {
        avatar: "/path/to/commenter1.jpg",
        username: "user1",
        text: "Nice post!",
      },
      {
        avatar: "/path/to/commenter2.jpg",
        username: "user2",
        text: "Great picture!",
      },
    ],
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative bg-white rounded-sm overflow-hidden flex flex-col md:flex-row w-1/2"
      >
        <div className="w-1/2">
          <Swiper
            modules={[Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            className="w-full h-full object-cover"
          >
            {post?.attachments?.map((attachment: any, idx: number) => (
              <SwiperSlide key={idx} >
                {isVideoAttachment(attachment) ? (
                  <video
                    src={imageUrlBuilder(attachment?.path)}
                    controls
                    className="w-full h-full object-cover"
                    autoPlay
                  />
                ) : (
                  <div className="flex items-center justify-center w-full h-full bg-gray-100">
                    <img
                      src={imageUrlBuilder(attachment?.path)}
                      alt={`Attachment ${idx}`}
                      className="w-full h-full object-cover object-center"
                    />
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex flex-col w-1/2">
          <div className="p-4 border-b border-gray-200 flex items-center justify-between">
            <div className="flex flex-row items-center w-full">
              <div className="rounded-full border-4 border-gray-300">
                <img
                  className="rounded-full border-2 border-white h-12 w-12 object-cover"
                  src={imageUrlBuilder(profile?.photo)}
                  alt="Profile"
                />
              </div>
              <div className="ml-4">
                <div className="text-xl font-semibold">{profile?.name}</div>
                <div className="text-sm">{profile?.username}</div>
              </div>
            </div>

            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          </div>
          <div className="p-4 flex-1 overflow-y-auto">
            <p className="mb-2">{post?.caption}</p>
            <div>
              {metadata?.comments.map((comment: any, index: any) => (
                <div key={index} className="flex items-start mb-2">
                  <div>
                    <span className="font-semibold mr-2">
                      {comment.username}
                    </span>
                    <span>{comment.text}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 border-t border-gray-200">
            <input
              type="text"
              placeholder="Add a comment..."
              className="w-full border rounded-full px-4 py-2 focus:outline-none focus:ring"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostModal;
