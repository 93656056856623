import React from "react";
import { Link } from "react-router-dom";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import { isVideoAttachment } from "../../helpers/is-video-attachment";

const Grid: React.FC<any> = ({ posts, onClick, username }: any) => {
  return (
    <div className="grid grid-cols-3 gap-x-1 gap-y-1 mt-4 max-w-lg w-full">
      {posts?.map((post: any, index: any) => {
        return (
          <>
            <Link to={`${username}/${post?.id}`} className="block sm:hidden">
              {isVideoAttachment(post?.attachments[0]) ? (
                <div
                  key={index}
                  className="relative w-100 h-100 lg:py-20 py-16 bg-gray-200"
                >
                  <video
                    src={imageUrlBuilder(post?.attachments[0]?.path)}
                    className="absolute top-0 left-0 object-cover w-full h-full"
                  />
                  {post.fixed === 1 && (
                    <div className="absolute top-2 right-2">
                      <svg
                        aria-label="Ícone de publicação fixada"
                        fill="#ffffff"
                        height="22"
                        role="img"
                        viewBox="0 0 24 24"
                        width="22"
                      >
                        <title>Ícone de publicação fixada</title>
                        <path d="m22.707 7.583-6.29-6.29a1 1 0 0 0-1.414 0 5.183 5.183 0 0 0-1.543 3.593L8.172 8.79a5.161 5.161 0 0 0-4.768 1.42 1 1 0 0 0 0 1.414l3.779 3.778-5.89 5.89a1 1 0 1 0 1.414 1.414l5.89-5.89 3.778 3.779a1 1 0 0 0 1.414 0 5.174 5.174 0 0 0 1.42-4.769l3.905-5.287a5.183 5.183 0 0 0 3.593-1.543 1 1 0 0 0 0-1.414Z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              ) : (
                <div key={index} className="relative py-16 bg-gray-200">
                  <img
                    src={imageUrlBuilder(post?.attachments[0]?.path)}
                    alt={`Imagem ${index + 1}`}
                    className="absolute top-0 left-0 object-cover w-full h-full"
                  />
                  {post.fixed === 1 && (
                    <div className="absolute top-2 right-2">
                      <svg
                        aria-label="Ícone de publicação fixada"
                        fill="#ffffff"
                        height="22"
                        role="img"
                        viewBox="0 0 24 24"
                        width="22"
                      >
                        <title>Ícone de publicação fixada</title>
                        <path d="m22.707 7.583-6.29-6.29a1 1 0 0 0-1.414 0 5.183 5.183 0 0 0-1.543 3.593L8.172 8.79a5.161 5.161 0 0 0-4.768 1.42 1 1 0 0 0 0 1.414l3.779 3.778-5.89 5.89a1 1 0 1 0 1.414 1.414l5.89-5.89 3.778 3.779a1 1 0 0 0 1.414 0 5.174 5.174 0 0 0 1.42-4.769l3.905-5.287a5.183 5.183 0 0 0 3.593-1.543 1 1 0 0 0 0-1.414Z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </Link>
            <div
              onClick={() => onClick(post)}
              className="hidden sm:block"
              key={index}
            >
              {isVideoAttachment(post?.attachments[0]) ? (
                <div
                  key={index}
                  className="relative w-100 h-100 py-20 bg-gray-200"
                >
                  <video
                    src={imageUrlBuilder(post?.attachments[0]?.path)}
                    className="absolute top-0 left-0 object-cover w-full h-full"
                  />
                  {post.fixed === 1 && (
                    <div className="absolute top-2 right-2">
                      <svg
                        aria-label="Ícone de publicação fixada"
                        fill="#ffffff"
                        height="22"
                        role="img"
                        viewBox="0 0 24 24"
                        width="22"
                      >
                        <title>Ícone de publicação fixada</title>
                        <path d="m22.707 7.583-6.29-6.29a1 1 0 0 0-1.414 0 5.183 5.183 0 0 0-1.543 3.593L8.172 8.79a5.161 5.161 0 0 0-4.768 1.42 1 1 0 0 0 0 1.414l3.779 3.778-5.89 5.89a1 1 0 1 0 1.414 1.414l5.89-5.89 3.778 3.779a1 1 0 0 0 1.414 0 5.174 5.174 0 0 0 1.42-4.769l3.905-5.287a5.183 5.183 0 0 0 3.593-1.543 1 1 0 0 0 0-1.414Z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  key={index}
                  className="relative w-100 h-100 py-20 bg-gray-200"
                >
                  <img
                    src={imageUrlBuilder(post?.attachments[0]?.path)}
                    alt={`Imagem ${index + 1}`}
                    className="absolute top-0 left-0 object-cover w-full h-full"
                  />
                  {post.fixed === 1 && (
                    <div className="absolute top-2 right-2">
                      <svg
                        aria-label="Ícone de publicação fixada"
                        fill="#ffffff"
                        height="22"
                        role="img"
                        viewBox="0 0 24 24"
                        width="22"
                      >
                        <title>Ícone de publicação fixada</title>
                        <path d="m22.707 7.583-6.29-6.29a1 1 0 0 0-1.414 0 5.183 5.183 0 0 0-1.543 3.593L8.172 8.79a5.161 5.161 0 0 0-4.768 1.42 1 1 0 0 0 0 1.414l3.779 3.778-5.89 5.89a1 1 0 1 0 1.414 1.414l5.89-5.89 3.778 3.779a1 1 0 0 0 1.414 0 5.174 5.174 0 0 0 1.42-4.769l3.905-5.287a5.183 5.183 0 0 0 3.593-1.543 1 1 0 0 0 0-1.414Z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default Grid;
