import { useEffect, useState, useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AppContext } from "./providers/appProvider";
import useLocalStorage from "./utils/use-local-storage";
import { getAuth } from "./services/basic-rest/auth/get-auth";
import LoadingPage from "./pages/Loading";

export const RoutePrivate = ({ component: Component, ...rest }: any) => {
  let location = useLocation();
  const [isLogged, setIsLogged] = useState<boolean | null>(null);
  const { setUser } = useContext(AppContext);
  const { accessToken } = useLocalStorage();

  useEffect(() => {
    accessToken !== null
      ? getAuth()
          .then((resp) => {
            const { me } = resp;
            setUser(me);
            setIsLogged(true);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setIsLogged(false);
            }
          })
      : setIsLogged(false);
  }, [isLogged, setUser, accessToken, location]);

  if (isLogged === null) {
    return <LoadingPage />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isLogged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/entrar", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
