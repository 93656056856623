import React from "react";
import StoriesSlider from "../StorySlider";

const HighlightCarousel: React.FC<any> = ({ highlights }: any) => {
  return (
    <>
      <div className="flex flex-col items-center py-4 px-2 w-full max-w-lg">
        <StoriesSlider highlights={highlights} />
      </div>
    </>
  );
};

export default HighlightCarousel;
