import React from "react";
import Spinner from "../../components/ui/Spinner";

const LoadingPage: React.FC<any> = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div className="flex flex-col items-center justify-around">
        <div className="mb-2">
          <Spinner />
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
