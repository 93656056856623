import { api } from "../../http";

export interface LoginInputDto {
  email: string;
  password: string;
  device_name: string;
}

export const getLogin = async (values: LoginInputDto): Promise<any> => {
  const response = api.post("/login", values).then(({ data }) => data);
  return response;
};
