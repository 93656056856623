import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import Textarea from "../../ui/TextArea";
import Button from "../../ui/Button";
import Media from "../../Media";
import { postPost } from "../../../services/basic-rest/posts/post-post";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { schemaErrorsPost } from "../../../validations/post-validation";

interface FormAddPostProps {
  caption: string;
  files: File[];
}

interface ParamTypes {
  id: string;
}

const FormPost: React.FC = () => {
  const formRefAddPost = useRef<FormHandles>(null);
  const { id } = useParams<ParamTypes>();
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit: SubmitHandler<FormAddPostProps> = async (data) => {
    const { files } = data;
    setLoading(true);

    try {
      formRefAddPost?.current?.setErrors({});

      await schemaErrorsPost.validate(data, {
        abortEarly: false,
      });

      if (files.length === 0) {
        formRefAddPost?.current?.setFieldError(
          "files",
          "Insira pelo menos 1 foto ou vídeo"
        );
        setLoading(false);
        return;
      }

      await postPost({ ...data, profile_id: String(id) })
        .then((resp) => {
          history.push(`/perfis/${id}`);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefAddPost?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <>
      <Form
        ref={formRefAddPost}
        onSubmit={handleSubmit}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="mt-8 space-y-6"
      >
        <Textarea name="caption" label="Legenda" />
        <Media name="files" />
        <Button text="Adicionar Publicação" loading={loading} />
      </Form>
      <Link
        to={`/perfis/${id}`}
        className="mt-2 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md group hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Voltar
      </Link>
    </>
  );
};

export default FormPost;
