import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import StoriesModal from "../StoriesModal";
import { imageUrlBuilder } from "../../helpers/image-url-builder";

export default function StoriesSlider({ highlights }: any) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);
  const [highlightActive, setHighlightActive] = useState<any>();

  const handleStoryClick = (highlight: any, index: any) => {
    setHighlightActive(highlight);
    setActiveStoryIndex(index);
    setIsModalOpen(true);
  };

  return (
    <div className="flex w-full px-2 pb-8 gap-4">
      {highlights?.map((highlight: any, index: any) => {
        const lastStoryPath =
          highlight?.stories[highlight.stories.length - 1]?.attachment?.path;

        return (
          <div className="flex flex-col justify-center">
            <div className="rounded-full border-4 border-gray-300 mb-2">
              <img
                className="rounded-full border-2 border-white h-20 w-20 object-cover cursor-pointer"
                src={imageUrlBuilder(lastStoryPath)}
                alt={highlight.title}
                onClick={() => handleStoryClick(highlight, index)}
              />
            </div>
            <span className="text-md text-center">{highlight.title}</span>
          </div>
        );
      })}
      {isModalOpen && (
        <StoriesModal
          stories={highlightActive?.stories}
          activeIndex={activeStoryIndex}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}
