import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Main from "../../components/Main";
import { getProfilesAll } from "../../services/basic-rest/profiles/get-profiles-all";
import { useQuery } from "react-query";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import { deleteProfile } from "../../services/basic-rest/profiles/delete-profile";
import Thumbnail from "../../components/Thumbnail";

const ProfileList: React.FC = () => {
  const [profiles, setProfiles] = useState<any>([]);
  const { data } = useQuery("profilesList", getProfilesAll);

  useEffect(() => {
    setProfiles(data);
  }, [data]);

  const onDeleteProfile = async (id: string) => {
    await deleteProfile(id)
      .then((resp) => {
        const newArray = [...profiles];
        const row = newArray.filter((profile: any) => profile.id !== id);
        setProfiles(row);
      })
      .catch((error) => {});
  };

  return (
    <Main>
      <div className="w-full lg:w-1/2 p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Lista de Perfis</h2>
        <table className="w-full mt-8 border-collapse">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Imagem</th>
              <th className="px-4 py-2 border">Nome</th>
              <th className="px-4 py-2 border">Username</th>
              <th className="px-4 py-2 border">Ações</th>
            </tr>
          </thead>
          <tbody>
            {profiles?.map((profile: any, index: any) => (
              <tr key={index}>
                <td className="border">
                  <div className="relative">
                    <img
                      className="w-32 h-32 object-cover"
                      src={imageUrlBuilder(profile?.photo)}
                      alt={`Post ${profile?.username}`}
                    />
                  </div>
                </td>
                <td className="px-4 py-2 border">{profile.name}</td>
                <td className="px-4 py-2 border">{profile.username}</td>
                <td className="px-4 py-2 border">
                  <Link to={`/perfis/${profile?.id}`}>
                    <button className="px-2 py-1 my-2 text-sm text-black bg-gray-200 rounded hover:bg-gray-400">
                      Editar
                    </button>
                  </Link>
                  <Link to={`/${profile?.username}`} target="_blank">
                    <button className="ml-2 px-2 py-1 my-2 text-sm text-black bg-gray-200 rounded hover:bg-gray-400">
                      Visualizar
                    </button>
                  </Link>
                  <button
                    onClick={() => onDeleteProfile(profile?.id)}
                    className="ml-4 px-2 py-1 my-2 text-sm text-white bg-red-500 rounded hover:bg-red-700"
                  >
                    Excluir
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Link to="/perfis/novo">
          <button className="w-full px-4 py-2 mt-4 text-white bg-indigo-600 rounded hover:bg-indigo-700">
            Adicionar Perfil
          </button>
        </Link>
      </div>
    </Main>
  );
};

export default ProfileList;
