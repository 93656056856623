import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import Textarea from "../../ui/TextArea";
import ImageInput from "../../ui/ImageInput";
import { useHistory } from "react-router-dom";
import { patchProfile } from "../../../services/basic-rest/profiles/patch-profile";
import * as Yup from "yup";
import { imageUrlBuilder } from "../../../helpers/image-url-builder";

const FormEditProfile: React.FC<any> = ({ profile }) => {
  const formRefEditProfile = useRef<FormHandles>(null);
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit: SubmitHandler<any> = async (data: any) => {
    setLoading(true);

    try {
      formRefEditProfile?.current?.setErrors({});

      // await schemaErrorsPost.validate(data, {
      //   abortEarly: false,
      // });

      await patchProfile(profile?.id, { ...data })
        .then((resp) => {
          history.push(`/perfis`);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefEditProfile?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <Form
      ref={formRefEditProfile}
      onSubmit={handleSubmit}
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="mt-8 space-y-6"
    >
      {/* {message && <Alert type="error" message={message} />} */}
      <ImageInput name="photo" defaultValue={profile?.photo && imageUrlBuilder(profile?.photo)} />
      <Input
        name="name"
        placeholder="Nome"
        label="Nome"
        defaultValue={profile?.name}
      />
      <Input
        name="username"
        placeholder="Username"
        label="Username"
        defaultValue={profile?.username}
      />
      <Textarea
        name="biography"
        label="Biografia"
        defaultValue={profile?.biography}
      />
      <Button text="Salvar alterações" />
    </Form>
  );
};

export default FormEditProfile;
