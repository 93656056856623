import React, { useRef, useState } from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import * as Yup from "yup";
import { getLogin } from "../../../services/basic-rest/auth/get-login";
import { useHistory } from "react-router-dom";
import { schemaErrorsLogin } from "../../../validations/login-validation";
import Alert from "../../ui/Alert";

interface FormLoginProps {
  email: string;
  password: string;
  device_name: string;
}

const FormLogin: React.FC<any> = () => {
  let history = useHistory();

  const formRefLogin = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleSubmit: SubmitHandler<FormLoginProps> = async (data) => {
    setLoading(true);
    try {
      formRefLogin?.current?.setErrors({});

      await schemaErrorsLogin.validate(data, {
        abortEarly: false,
      });

      await getLogin({ ...data, device_name: "app" })
        .then((resp) => {
          const { token } = resp;
          if (token) {
            localStorage.setItem("access_token", token);
            history.push("/perfis");
          }
        })
        .catch((error) => {
          setMessage(error?.response?.data?.message);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefLogin?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <Form
      ref={formRefLogin}
      onSubmit={handleSubmit}
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="mt-8 space-y-6"
    >
      {message && <Alert type="error" message={message} />}
      <Input name="email" placeholder="E-mail" />
      <Input name="password" placeholder="Senha" type="password" />
      <Button text="Entrar" loading={loading} />
    </Form>
  );
};

export default FormLogin;
