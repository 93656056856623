import FormNewStory from "../../components/Forms/FormNewStory";
import Main from "../../components/Main";

const AddStory = () => {
  return (
    <Main>
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Adicionar Story</h2>
        <FormNewStory />
      </div>
    </Main>
  );
};

export default AddStory;
