import React from "react";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-10">
      <h1 className="font-bold mb-10 text-2xl">Página não encontrada!</h1>
      <Link to="/perfis" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Voltar ao Painel
      </Link>
    </div>
  );
};

export default NotFound;
