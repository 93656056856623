import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import React, { useRef } from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import Textarea from "../../ui/TextArea";
import ImageInput from "../../ui/ImageInput";
import { postProfile } from "../../../services/basic-rest/profiles/post-profile";
import * as Yup from "yup";
import { schemaErrorsProfile } from "../../../validations/profile-validation";
import { useHistory } from "react-router-dom";

const FormNewProfile: React.FC<any> = () => {
  const formRefNewProfile = useRef<FormHandles>(null);
  let history = useHistory();

  const handleSubmit: SubmitHandler<any> = async (data) => {
    console.log("data", data);
    try {
      formRefNewProfile?.current?.setErrors({});
      await schemaErrorsProfile.validate(data, {
        abortEarly: false,
      });

      await postProfile({ ...data })
        .then((resp) => {
          history.push("/perfis");
        })
        .catch((error) => {
          console.log("error", error);

          // setMessage(error?.response?.data?.message);
          // setLoading(false);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (err) {
      // setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefNewProfile?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <Form
      ref={formRefNewProfile}
      onSubmit={handleSubmit}
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="mt-8 space-y-6"
    >
      {/* {message && <Alert type="error" message={message} />} */}
      <ImageInput name="photo" />
      <Input name="name" placeholder="Nome" label="Nome" />
      <Input name="username" placeholder="Username" label="Username" />
      <Textarea name="biography" label="Biografia" />
      <Button text="Criar perfil" />
    </Form>
  );
};

export default FormNewProfile;
