import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faComment,
  faShare,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getPostById } from "../../services/basic-rest/posts/get-post-by-id";
import { getProjectByUsername } from "../../services/basic-rest/projects/get-project-by-id";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { isVideoAttachment } from "../../helpers/is-video-attachment";

interface ParamTypes {
  username: string;
  postId: string;
}

const Post: React.FC = () => {
  const { username, postId } = useParams<ParamTypes>();

  const { data: post } = useQuery(["post", postId], () =>
    getPostById(postId as string)
  );

  const { data: profile } = useQuery(["project_", username], () =>
    getProjectByUsername(username as string)
  );

  console.log("profile", profile);

  return (
    <div className="flex flex-col items-center min-h-screen w-full">
      <div className="flex flex-col bg-white w-full max-w-lg pt-4">
        {/* <div className="flex flex-col bg-white w-full max-w-lg px-2 py-4">
          <div className="container flex justify-between">
            <Link to="/" className="text-blue-500 font-semibold">
              <i className="fas fa-arrow-left mr-2"></i> Voltar
            </Link>
          </div>
        </div> */}
        {/* Left side: Post image */}
        {/* User info */}
        <div className="flex items-center mb-4 px-4">
          <div className="rounded-full border-4 border-gray-300">
            <img
              className="rounded-full border-2 border-white h-12 w-12 object-cover"
              src={imageUrlBuilder(profile?.photo)}
              alt="User"
            />
          </div>
          <div className="ml-3">
            <Link to={"/" + username} className="text-sm font-semibold">
              {profile?.username}
            </Link>

            <div className="text-xs text-gray-500">{profile?.name}</div>
          </div>
        </div>
        <div className="w-full">
          {/* <img
            className="w-full h-full object-cover"
            src={imageUrlBuilder(post?.attachments[0]?.path)}
            alt={post?.caption}
          /> */}
          <Swiper
            modules={[Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            // className="w-full h-full object-cover"
          >
            {post?.attachments?.map((attachment: any, idx: number) => (
              <SwiperSlide key={idx}>
                {isVideoAttachment(attachment) ? (
                  <video
                    src={imageUrlBuilder(attachment?.path)}
                    controls
                    autoPlay
                  />
                ) : (
                  <img
                    className="w-full h-full object-cover"
                    src={imageUrlBuilder(attachment?.path)}
                    alt={`Post ${post?.caption}`}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* Right side: Post details */}
        <div className="w-full p-4 flex flex-col justify-between">
          <div>
            {/* Post actions */}
            <div className="flex justify-between items-center mb-4">
              <button className="text-gray-700 pe-4">
                <FontAwesomeIcon icon={faHeart} size="xl" />
              </button>
              <button className="text-gray-700 pe-4">
                <FontAwesomeIcon icon={faComment} size="xl" />
              </button>
              <button className="text-gray-700 pe-4">
                <FontAwesomeIcon icon={faShare} size="xl" />
              </button>
              <button className="text-gray-700 ml-auto">
                <FontAwesomeIcon icon={faBookmark} size="xl" />
              </button>
            </div>
            {/* Likes */}
            <div className="mb-4">
              <span className="font-semibold">3.932 Curtidas</span>
            </div>
            {/* Post caption */}
            <div className="mb-4">
              <span className="font-semibold">username</span> {post?.caption}
            </div>

            <span className="pb-3 block text-sm font-medium text-gray-400">
              3 hours ago
            </span>

            {/* Comments */}
            <div className="overflow-y-auto flex-grow">
              <div className="mb-4">
                <span className="font-semibold">comment_user1</span> Great post!
              </div>
              <div className="mb-4">
                <span className="font-semibold">comment_user2</span> Love this!
              </div>
              {/* More comments */}
              {/* You can map through an array of comments */}
            </div>
          </div>
          {/* Add a comment */}
          <div className="border-t pt-4">
            <input
              type="text"
              placeholder="Add a comment..."
              className="w-full px-4 py-2 rounded-full border focus:outline-none focus:ring"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
