// Função para verificar se o anexo é um vídeo
export const isVideoAttachment = (attachment: any) => {
  // Obtenha a extensão do arquivo a partir do caminho do arquivo
  const extension = attachment?.path.split(".").pop()?.toLowerCase();
  // Lista de tipos MIME de vídeo
  const videoMimeTypes = ["video/mp4", "video/webm", "video/ogg"];
  // Verifique se a extensão ou o tipo MIME correspondem a um vídeo
  return (
    videoMimeTypes.includes(attachment?.type) ||
    extension === "mp4" ||
    extension === "webm" ||
    extension === "ogg"
  );
};
