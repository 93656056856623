import { apiAuth } from "../../http";

export const patchProfile = async (
  id: string,
  body: FormData
): Promise<any> => {
  try {
    const response = await apiAuth().post(`/profiles/${id}?_method=PATCH`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "PATCH",
    });
    return response.data;
  } catch (error) {
    console.error("Error patching profile:", error);
    throw error;
  }
};
