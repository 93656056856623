import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { postHighLight } from "../../../services/basic-rest/highlight/post-highlight";
import { patchHighLight } from "../../../services/basic-rest/highlight/patch-highlight";

interface FormEditHighLightProps {
  title: string;
  profile_id: string;
}

interface ParamTypes {
  id: string;
  highlightId: string;
}

const FormEditHighLight: React.FC<any> = ({ highlight }: any) => {
  const formRefEditHighLight = useRef<FormHandles>(null);
  const { id, highlightId } = useParams<ParamTypes>();
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit: SubmitHandler<FormEditHighLightProps> = async (data) => {
    setLoading(true);

    try {
      formRefEditHighLight?.current?.setErrors({});

      //   await schemaErrorsPost.validate(data, {
      //     abortEarly: false,
      //   });

      await patchHighLight(highlightId, { ...data, profile_id: String(id) })
        .then((resp) => {
          history.push(`/perfis/${id}`);
        })
        .catch((error) => {
          console.log("error", error);

          // setMessage(error?.response?.data?.message);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefEditHighLight?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <>
      <Form
        ref={formRefEditHighLight}
        onSubmit={handleSubmit}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="mt-8 space-y-6"
      >
        <Input
          name="title"
          placeholder="Título"
          defaultValue={highlight?.title}
        />

        <Button text="Salvar alterações" loading={loading} />
      </Form>
      <Link
        to={`/perfis/${id}`}
        className="mt-2 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md group hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Voltar
      </Link>
    </>
  );
};

export default FormEditHighLight;
