// src/AddProfile.js
import React, { useState } from "react";
import FormNewProfile from "../../components/Forms/FormNewProfile";
import { Link } from "react-router-dom";
import Main from "../../components/Main";

const AddProfile: React.FC = () => {
  return (
    <Main>
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Adicionar Perfil</h2>
        <FormNewProfile />
        <Link
          to="/perfis"
          className="mt-2 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md group hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Voltar
        </Link>
      </div>
    </Main>
  );
};

export default AddProfile;
