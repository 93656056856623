import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import HighlightCarousel from "../../components/HighlightCarousel";
import { useQuery } from "react-query";
import { getProjectByUsername } from "../../services/basic-rest/projects/get-project-by-id";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import PostModal from "../../components/PostModal";
import Grid from "../../components/Grid";

interface ParamTypes {
  username: string;
}

const Project: React.FC = () => {
  const { username } = useParams<ParamTypes>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postActive, setPostActive] = useState<any>();

  const { data } = useQuery(["project_", username], () =>
    getProjectByUsername(username as string)
  );

  const handlePostModal = (post: any) => {
    setPostActive(post);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="flex items-center justify-center min-h-screen w-full">
      <div className="flex flex-col items-center min-h-screen w-full">
        <div className="flex flex-col items-center bg-white py-4 px-4 w-full max-w-lg">
          <div className="flex flex-row items-center w-full">

            <div className="rounded-full border-4 border-gray-300">
              <img
                className="rounded-full border-2 border-white h-24 w-24 object-cover"
                src={imageUrlBuilder(data?.photo)}
                alt="Profile"
              />
            </div>

            <div className="ml-4 w-1/2">
              <div className="text-xl font-semibold">{data?.name}</div>
              <div className="text-sm">{data?.username}</div>
              <div className="text-gray-500 text-sm mt-2">
                {data?.biography}
              </div>
            </div>

          </div>
          <div className="flex justify-around w-full mt-4 text-center">
            <div>
              <div className="text-xl font-semibold">123</div>
              <div className="text-gray-500">Publicações</div>
            </div>
            <div>
              <div className="text-xl font-semibold">13k</div>
              <div className="text-gray-500">Seguidores</div>
            </div>
            <div>
              <div className="text-xl font-semibold">789</div>
              <div className="text-gray-500">Seguindo</div>
            </div>
          </div>
        </div>
        <HighlightCarousel highlights={data?.highlights} />
        <div className="grid grid-cols-3 gap-x-1 gap-y-1 mt-4 max-w-lg w-full">
          <div className="flex flex-col items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.16667 0H15.8333C16.938 0.00132321 17.997 0.440735 18.7782 1.22185C19.5593 2.00296 19.9987 3.062 20 4.16667V15.8333C19.9987 16.938 19.5593 17.997 18.7782 18.7782C17.997 19.5593 16.938 19.9987 15.8333 20H4.16667C3.062 19.9987 2.00296 19.5593 1.22185 18.7782C0.440735 17.997 0.00132321 16.938 0 15.8333L0 4.16667C0.00132321 3.062 0.440735 2.00296 1.22185 1.22185C2.00296 0.440735 3.062 0.00132321 4.16667 0ZM18.3333 6.15327V4.16667C18.3333 3.50363 18.0699 2.86774 17.6011 2.3989C17.1323 1.93006 16.4964 1.66667 15.8333 1.66667H13.9778V6.15327H18.3333ZM6.09194 1.66667H4.16667C3.50363 1.66667 2.86774 1.93006 2.3989 2.3989C1.93006 2.86774 1.66667 3.50363 1.66667 4.16667V6.15327H6.09194V1.66667ZM1.66667 13.8435V15.8333C1.66667 16.4964 1.93006 17.1323 2.3989 17.6011C2.86774 18.0699 3.50363 18.3333 4.16667 18.3333H6.09194V13.8435H1.66667ZM13.9778 18.3333H15.8333C16.4964 18.3333 17.1323 18.0699 17.6011 17.6011C18.0699 17.1323 18.3333 16.4964 18.3333 15.8333V13.8435H13.9778V18.3333ZM6.09194 7.81994H1.66667V12.1768H6.09194V7.81994ZM13.9778 7.81994H18.3333V12.1768H13.9778V7.81994ZM12.3111 6.15327V1.66667H7.75861V6.15327H12.3111ZM7.75861 7.81994V12.1768H12.3111V7.81994H7.75861ZM7.75861 13.8435V18.3333H12.3111V13.8435H7.75861Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="flex flex-col items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.328 6.63923L7.59415 0.805045C6.97313 0.350299 6.2383 0.0764332 5.47114 0.0138148C4.70398 -0.0488035 3.93447 0.102272 3.24792 0.450291C2.56138 0.798309 1.98464 1.32967 1.58165 1.98546C1.17866 2.64124 0.965165 3.39583 0.964844 4.16554V15.8339C0.964981 16.604 1.17849 17.3589 1.58166 18.015C1.98484 18.6711 2.56192 19.2026 3.24886 19.5506C3.9358 19.8986 4.70573 20.0495 5.47321 19.9865C6.24069 19.9234 6.97569 19.649 7.59665 19.1936L17.3305 13.3594C17.8583 12.9723 18.2875 12.4664 18.5833 11.8826C18.8792 11.2987 19.0333 10.6534 19.0333 9.99889C19.0333 9.34438 18.8792 8.69907 18.5833 8.11523C18.2875 7.5314 17.8583 7.02546 17.3305 6.6384L17.328 6.63923ZM16.3411 12.015L6.60734 17.8492C6.2348 18.1214 5.79419 18.2853 5.3343 18.3226C4.8744 18.3599 4.41315 18.2692 4.0016 18.0606C3.59005 17.852 3.24425 17.5335 3.00248 17.1405C2.76071 16.7476 2.6324 16.2953 2.63176 15.8339V4.16554C2.62713 3.70326 2.75302 3.24906 2.99496 2.85512C3.23689 2.46117 3.58506 2.14348 3.99946 1.93854C4.35192 1.75926 4.74168 1.6656 5.13712 1.66517C5.66711 1.6672 6.18246 1.83927 6.60734 2.15608L16.3411 7.99027C16.6574 8.22255 16.9146 8.52601 17.0919 8.87612C17.2691 9.22623 17.3615 9.61314 17.3615 10.0056C17.3615 10.398 17.2691 10.7849 17.0919 11.135C16.9146 11.4851 16.6574 11.7886 16.3411 12.0209V12.015Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="flex flex-col items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 0H4.16667C3.062 0.00132321 2.00296 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H15.8333C16.938 19.9987 17.997 19.5593 18.7782 18.7782C19.5593 17.997 19.9987 16.938 20 15.8333V4.16667C19.9987 3.062 19.5593 2.00296 18.7782 1.22185C17.997 0.440735 16.938 0.00132321 15.8333 0V0ZM4.16667 1.66667H15.8333C16.4964 1.66667 17.1323 1.93006 17.6011 2.3989C18.0699 2.86774 18.3333 3.50363 18.3333 4.16667V15.8333C18.3319 16.2046 18.2464 16.5707 18.0833 16.9042L10.4475 9.26833C10.0606 8.88131 9.6012 8.57429 9.0956 8.36483C8.59001 8.15537 8.0481 8.04756 7.50083 8.04756C6.95357 8.04756 6.41166 8.15537 5.90607 8.36483C5.40047 8.57429 4.94109 8.88131 4.55417 9.26833L1.66667 12.155V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667V1.66667ZM4.16667 18.3333C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V14.5117L5.73167 10.4467C5.96383 10.2144 6.2395 10.0301 6.54292 9.90432C6.84634 9.77858 7.17156 9.71387 7.5 9.71387C7.82844 9.71387 8.15366 9.77858 8.45708 9.90432C8.7605 10.0301 9.03617 10.2144 9.26833 10.4467L16.9042 18.0833C16.5707 18.2464 16.2046 18.3319 15.8333 18.3333H4.16667Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <Grid
          posts={data?.posts}
          onClick={handlePostModal}
          username={username}
        />
      </div>
      <PostModal
        isOpen={isModalOpen}
        post={postActive}
        onClose={() => setIsModalOpen(false)}
        profile={data}
      />
    </div>
  );
};

export default Project;
