import React from "react";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import { isVideoAttachment } from "../../helpers/is-video-attachment";

type ThumbnailProps = {
  attachment: string;
};

const Thumbnail: React.FC<ThumbnailProps> = ({ attachment }: any) => {
  return isVideoAttachment(attachment) ? (
    <video
      className="w-32 h-32 object-cover"
      src={imageUrlBuilder(attachment?.path)}
    />
  ) : (
    <img
      className="w-32 h-32 object-cover"
      src={imageUrlBuilder(attachment?.path)}
      alt={`Post ${attachment?.caption}`}
    />
  );
};

export default Thumbnail;
