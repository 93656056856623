import FormPost from "../../components/Forms/FormPost";
import Main from "../../components/Main";

const AddPost = () => {
  return (
    <Main>
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Adicionar Publicação</h2>
        <FormPost />
      </div>
    </Main>
  );
};

export default AddPost;
