import { useState, createContext } from "react";
import { useHistory } from "react-router-dom";
import { getLogout } from "../services/basic-rest/auth/get-logout";

export interface PropsAppTypes {
  user: any | null;
  setUser: (user: any) => void;

  handleLogoutUser: () => void;
}

export const AppContext = createContext({} as PropsAppTypes);

export function AppProvider({ children }: any) {
  const history = useHistory();

  const [user, setUser] = useState<any | null>(null);

  const handleLogoutUser = async () => {
    await getLogout()
      .then((resp) => {})
      .catch((error) => {});
    localStorage.removeItem("access_token");
    history.push("/entrar");
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        handleLogoutUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
