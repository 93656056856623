import React from "react";
import { Route, BrowserRouter as Routers, Switch } from "react-router-dom";

import Project from "./pages/Project";
import Post from "./pages/Post";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/queryClient";
import Login from "./pages/Login";
import ProfileList from "./pages/Profiles";
import AddProfile from "./pages/NewProfile";
import AddPost from "./pages/AddPost";
import EditProfile from "./pages/EditProfile";
import AddHighLight from "./pages/AddHighLight";
import EditHighLight from "./pages/EditHighLight";
import AddStory from "./pages/AddStory";
import NotFound from "./pages/NotFound";
import { AppProvider } from "./providers/appProvider";
import { RoutePublic } from "./route-public";
import { RoutePrivate } from "./route-private";
import EditPost from "./pages/EditPost";
import EditStory from "./pages/EditStory";

const Routes = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routers>
        <AppProvider>
          <Switch>
            <RoutePublic exact component={Login} path="/entrar" />

            <RoutePrivate exact component={ProfileList} path="/perfis" />
            <RoutePrivate exact component={AddProfile} path="/perfis/novo" />
            <RoutePrivate exact component={EditProfile} path="/perfis/:id" />

            <RoutePrivate
              exact
              component={AddPost}
              path="/perfis/:id/posts/novo"
            />
            <RoutePrivate
              exact
              component={EditPost}
              path="/perfis/:id/posts/:postId"
            />
            <RoutePrivate
              exact
              component={AddHighLight}
              path="/perfis/:id/destaques/novo"
            />

            <RoutePrivate
              exact
              component={EditHighLight}
              path="/perfis/:id/destaques/:highlightId"
            />
            <RoutePrivate
              exact
              component={AddStory}
              path="/perfis/:id/destaques/:highlightId/story/novo"
            />
            <RoutePrivate
              exact
              component={EditStory}
              path="/perfis/:id/destaques/:highlightId/story/:storyId"
            />
            {/* Projetos */}
            <Route exact component={Project} path="/:username" />
            <Route exact component={Post} path="/:username/:postId" />

            <RoutePublic exact component={NotFound} path="*" />
          </Switch>
        </AppProvider>
      </Routers>
    </QueryClientProvider>
  );
};

export default Routes;
