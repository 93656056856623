import React, { useContext } from "react";
import { AppContext } from "../../providers/appProvider";

const Main: React.FC<any> = ({ children }: any) => {
  const { user, handleLogoutUser } = useContext(AppContext);
  return (
    <div className="flex items-center flex-col justify-center min-h-screen bg-gray-100">
      <header className="w-full lg:w-1/2 flex justify-between items-center px-2 py-4">
        <h1 className="text-sm font-semibold">Olá, {user?.name}</h1>
        <button
          onClick={handleLogoutUser}
          className="text-sm font-medium text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          Sair
        </button>
      </header>
      {children}
      <footer className="my-10">
        <p className="text-sm">
          Desenvolvido por {" "}
          <a
            href="https://wa.me/5521965234696?text=Olá,%20Gustavo!"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700"
          >
            Gustavo Mendes
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Main;
