import { apiAuth } from "../../http";

export const postStory = async (body: any): Promise<any> => {
  const response = apiAuth()
    .post("/stories", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data);
  return response;
};
