import React, { useState, useEffect } from "react";

const Alert: React.FC<any> = ({ type, message }: any) => {
  const [visible, setVisible] = useState(true);

//   useEffect(() => {
//     // Define um temporizador para ocultar o alerta após 5 segundos
//     const timer = setTimeout(() => {
//       setVisible(false);
//       message = null;
//     }, 5000);

//     // Limpa o temporizador ao desmontar o componente
//     return () => clearTimeout(timer);
//   }, []); // Executa apenas uma vez, quando o componente é montado

  // Determina as classes CSS com base no tipo de alerta
  let alertClasses = "px-4 py-2 rounded-lg";
  if (type === "success") {
    alertClasses += " bg-green-200 text-green-800";
  } else if (type === "error") {
    alertClasses += " bg-red-200 text-red-800";
  } else if (type === "warning") {
    alertClasses += " bg-yellow-200 text-yellow-800";
  } else if (type === "info") {
    alertClasses += " bg-blue-200 text-blue-800";
  }

  return (
    <>
      {visible && (
        <div className={alertClasses}>
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

export default Alert;
