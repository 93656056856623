import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from "react";
import Textarea from "../../ui/TextArea";
import Button from "../../ui/Button";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getStoryById } from "../../../services/basic-rest/stories/get-story-by-id";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { isVideoAttachment } from "../../../helpers/is-video-attachment";
import { imageUrlBuilder } from "../../../helpers/image-url-builder";
import { schemaErrorsPost } from "../../../validations/post-validation";
import { patchStoryById } from "../../../services/basic-rest/stories/patch-story-by-id";

interface FormAddPostProps {
  caption: string;
  type: string;
}

interface ParamTypes {
  id: string;
  storyId: string;
  highlightId: string;
}

const FormEditStory: React.FC = () => {
  const formRefEditStory = useRef<FormHandles>(null);
  const { storyId, id, highlightId } = useParams<ParamTypes>();
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const { data, refetch } = useQuery(`story_${storyId}`, () =>
    getStoryById(storyId)
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit: SubmitHandler<FormAddPostProps> = async (data: any) => {
    console.log("data", data);
    setLoading(true);

    try {
      formRefEditStory?.current?.setErrors({});

      await schemaErrorsPost.validate(data, {
        abortEarly: false,
      });

      await patchStoryById(storyId, {
        ...data,
      })
        .then((resp) => {
          history.push(`/perfis/${id}/destaques/${highlightId}`);
        })
        .catch((error) => {
          console.log("error", error);

          // setMessage(error?.response?.data?.message);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefEditStory?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <>
      <Form
        ref={formRefEditStory}
        onSubmit={handleSubmit}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="mt-8 space-y-6"
      >
        <div className="relative">
          {isVideoAttachment(data?.attachment) ? (
            <video
              src={imageUrlBuilder(data?.attachment?.path)}
              controls
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={imageUrlBuilder(data?.attachment?.path)}
              alt={`Attachment`}
              className="w-full h-full object-cover"
            />
          )}
        </div>
        <Textarea name="caption" label="Legenda" defaultValue={data?.caption} />
        <Button text="Salvar alterações" loading={loading} />
      </Form>
      <Link
        to={`/perfis/${id}/destaques/${highlightId}`}
        className="mt-2 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md group hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Voltar
      </Link>
    </>
  );
};

export default FormEditStory;
