import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { imageUrlBuilder } from "../../helpers/image-url-builder";
import { isVideoAttachment } from "../../helpers/is-video-attachment";

export default function StoriesModal({ stories, activeIndex, onClose }: any) {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="relative w-full max-w-3xl"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-4 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <Swiper
          modules={[Pagination]}
          initialSlide={activeIndex}
          navigation
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          className="w-full h-full"
        >
          {stories?.map((story: any, index: any) => (
            <SwiperSlide key={index}>
              <div className="flex justify-center items-center h-full">
                {isVideoAttachment(story?.attachment) ? (
                  <video
                    // autoPlay
                    controls
                    className="max-h-screen  object-contain"
                    src={imageUrlBuilder(story.attachment?.path)}
                  />
                ) : (
                  <img
                    src={imageUrlBuilder(story.attachment?.path)}
                    alt={story.title}
                    className="max-h-screen  object-contain"
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
