import FormEditPost from "../../components/Forms/FormEditPost";
import Main from "../../components/Main";

const EditPost = () => {
  return (
    <Main>
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Editar Publicação</h2>
        <FormEditPost />
      </div>
    </Main>
  );
};

export default EditPost;
