import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import Textarea from "../../ui/TextArea";
import Button from "../../ui/Button";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { schemaErrorsPost } from "../../../validations/post-validation";
import { patchPostById } from "../../../services/basic-rest/posts/patch-post-by-id";
import { getPostById } from "../../../services/basic-rest/posts/get-post-by-id";
import { useQuery } from "react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { isVideoAttachment } from "../../../helpers/is-video-attachment";
import { imageUrlBuilder } from "../../../helpers/image-url-builder";
import Switch from "../../ui/Switch";

interface FormAddPostProps {
  caption: string;
  fixed: number;
}

interface ParamTypes {
  id: string;
  postId: string;
}

const FormEditPost: React.FC = () => {
  const formRefAddPost = useRef<FormHandles>(null);
  const { postId, id } = useParams<ParamTypes>();
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const { data } = useQuery(`post_${postId}`, () => getPostById(postId));

  const handleSubmit: SubmitHandler<FormAddPostProps> = async (data) => {
    setLoading(true);

    try {
      formRefAddPost?.current?.setErrors({});

      await schemaErrorsPost.validate(data, {
        abortEarly: false,
      });

      await patchPostById(postId, { ...data, fixed: Number(data.fixed) })
        .then((resp) => {
          history.push(`/perfis/${id}`);
        })
        .catch((error) => {
          console.log("error", error);

          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      const errorList: any = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (typeof error.path === "string") {
            errorList[error?.path ?? 0] = error.message;
          }
        });

        formRefAddPost?.current?.setErrors(errorList);
      }
    }
  };

  return (
    <>
      <Form
        ref={formRefAddPost}
        onSubmit={handleSubmit}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="mt-8 space-y-6"
        initialData={data}
      >
        <div className="relative">
          <Swiper
            modules={[Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            className="w-full h-full object-cover"
          >
            {data?.attachments?.map((attachment: any, idx: number) => (
              <SwiperSlide key={idx}>
                {isVideoAttachment(attachment) ? (
                  <video
                    src={imageUrlBuilder(attachment?.path)}
                    controls
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <img
                    src={imageUrlBuilder(attachment?.path)}
                    alt={`Attachment ${idx}`}
                    className="w-full h-full object-cover"
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Textarea name="caption" label="Legenda" defaultValue={data?.caption} />
        <Switch name="fixed" />
        <Button text="Salvar alterações" loading={loading} />
      </Form>
      <Link
        to={`/perfis/${id}`}
        className="mt-2 relative flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md group hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Voltar
      </Link>
    </>
  );
};

export default FormEditPost;
