export function imageUrlBuilder(imagePath: string): string {
  const api = process.env.REACT_APP_BASE_API;
  const storage = "/storage";

  if (!imagePath) {
    return `${api}${storage}/default-image.png`;
  }

  return `${api}${storage}/${imagePath}`;
}
